import Flow from 'project/flow/Flow';
import React from 'react';
import GlobalFonts from 'shared/utils/GlobalFonts';
import { HashRouter } from 'react-router-dom';
import { ModalProvider } from "styled-react-modal";

function App() {
  return (
    <>
      <GlobalFonts/>
      <ModalProvider>
        <HashRouter hashType="noslash">
          <Flow/>
        </HashRouter>
      </ModalProvider>
    </>
  );
}

export default App;
