import { yupResolver } from '@hookform/resolvers';
import * as React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ReactComponent as DaySVG } from 'shared/assets/svgs/day.svg';
import SetupPage, {
  Error,
  Form,
  getPronoun2,
  Input,
  MainContainer,
  PageProps,
  SVGContainer,
  SVG_PROPS,
  Submit,
  InputContainer,
} from 'shared/components/SetupPage';
import { Header, Theme } from 'shared/utils/styles';
import moment from 'moment';
import styled from 'styled-components';
import * as yup from 'yup';
import 'configurable-date-input-polyfill';

const maxDate = moment().format('YYYY-MM-DD');

type Inputs = {
  date: string;
};

let schema = yup.object().shape({
  date: yup.date().required(),
});

const formatBirthDate = (data: any) => {
  const { day, month, year } = data;
  if (isNaN(day) || isNaN(month) || isNaN(year)) {
    return undefined;
  }
  const date = moment();
  date.year(year);
  date.month(month - 1);
  date.date(day);
  date.startOf('day');

  return date.isValid() ? date.format('YYYY-MM-DD') : undefined;
};
const parseBirthDate = (date: any): any => {
  const mDate = moment(date);

  if (!mDate.isValid()) {
    throw new TypeError('Please enter a valid birthdate.');
  }
  const year = mDate.year();
  if (year < 1900 || year > moment().year()) {
    throw new TypeError('Please enter a valid year.');
  }
  return {
    day: mDate.date(),
    year: mDate.year(),
    month: mDate.month() + 1,
  };
};

const BirthDate: React.FC<PageProps> = (props) => {
  const { control, register, handleSubmit, setError, errors, watch } = useForm<Inputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      date: formatBirthDate(props.signupData),
    },
  });

  const dateInputVal = watch('date');

  const onSubmit = (data: any) => {
    console.log(data.date);
    try {
      const newData = parseBirthDate(data.date);
      props.setNewData(newData);
      props.onNext();
    } catch (error) {
      setError('date', error);
    }
  };

  return (
    <SetupPage {...props}>
      <MainContainer>
        <SVGContainer>
          <DaySVG {...SVG_PROPS} />
        </SVGContainer>
        <Header>What date {getPronoun2(props.signupData)} born on?</Header>

        <FormContainer>
          <FormStyled onSubmit={handleSubmit(onSubmit)}>
            <InputContainer>
              <Controller
                control={control}
                name="date"
                render={({ onChange, value, name }) => {
                  return (
                    <InputStyled
                      name={name}
                      value={value || ''}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        onChange(e.target.value.replace(/[^0-9\-\/]/g, ''))
                      }
                      type="date"
                      min="1900-01-01"
                      max={maxDate}
                      data-date-format="mm/dd/yyyy"
                      date-format="mm/dd/yyyy"
                      data-placeholder="mm/dd/yyyy"
                      placeholder="mm/dd/yyyy"
                      className={!!dateInputVal ? 'has-value' : 'has-empty-value'}
                    />
                  );
                }}
              />
              {errors.date && <Error>Please enter a valid birthdate (mm/dd/yyyy).</Error>}
            </InputContainer>

            <Submit />
          </FormStyled>
        </FormContainer>
      </MainContainer>
    </SetupPage>
  );
};
export default BirthDate;

export const FormContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  @media (min-width: 640px) {
    padding-left: 34px;
    padding-right: 34px;
  }
`;

export const FormContainerTextArea = styled(FormContainer)`
  padding-left: 34px;
  padding-right: 34px;
  width: 100%;
  box-sizing: border-box;
`;

export const FormStyled = styled(Form)`
  width: 100%;
`;

export const InputStyled = styled(Input)`
  flex: 1;
  min-height: 45px;

  ::-webkit-datetime-edit-month-field,
  ::-webkit-datetime-edit-day-field,
  ::-webkit-datetime-edit-year-field,
  ::-webkit-datetime-edit-hour-field,
  ::-webkit-datetime-edit-minute-field,
  ::-webkit-datetime-edit-meridiem-field,
  ::-webkit-datetime-edit-ampm-field,
  ::-webkit-datetime-edit-text {
    &:focus {
      background-color: #b3d5e6;
      color: ${Theme.black};
    }
    color: ${Theme.black};
  }

  ::-webkit-date-and-time-value {
    text-align: left;
  }
  &.has-empty-value:not(:invalid) {
    color: rgba(53, 34, 107, 0.3);

    &::-webkit-date-and-time-value {
      height: 100%;
      width: 100%;
      min-height: 24px;
      background-image: url('/ios-date-placeholder.svg');
      background-size: 91px;
      background-repeat: no-repeat;
      background-position: left center;
    }
  }
`;

// 2020-11-04
function formatDate(date: Date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
}
