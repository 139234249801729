import { yupResolver } from '@hookform/resolvers';
import * as React from 'react';
import { useRef, useMemo, useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ReactComponent as SVG } from 'shared/assets/svgs/globe.svg';
import { ReactComponent as PencilIcon } from 'shared/assets/svgs/pencil.svg';
import SetupPage, {
  Input,
  ErrorView,
  getPronoun2,
  InputContainer,
  MainContainer,
  PageProps,
  Submit,
  SVGContainer,
  BottomText,
  LOCATION,
  getPronounStatement,
} from 'shared/components/SetupPage';
import { Header, Theme } from 'shared/utils/styles';
import styled from 'styled-components';
import * as yup from 'yup';
import { FormContainer, FormStyled } from './BirthDate';
import { Loader } from '@googlemaps/js-api-loader';

type Inputs = LOCATION & { selectedAddress: string; selectedDisplayAddress: string };

const getDisplayAddressFromPlace = (place: google.maps.places.PlaceResult): string | undefined => {
  const { address_components, geometry } = place;
  if (typeof geometry?.location === 'undefined' || typeof address_components === 'undefined') {
    return;
  }

  const country = address_components.find((c) => !!c.types.find((t) => t === 'country'));

  if (!country) {
    return;
  }

  const region =
    (country.short_name === 'US' &&
      address_components.find((c) => !!c.types.find((t) => t === 'administrative_area_level_1'))) ||
    country;

  // Get first place considered a locality for town.
  // Starts with locality, then tries political and sublocality designations.
  let town = address_components.find(
    (c) =>
      c !== region && c !== country && !!c.types.find((t) => /^(locality|postal_town)/.test(t)),
  );
  if (!town || town === region) {
    town = address_components.find(
      (c) =>
        c !== region &&
        c !== country &&
        !!c.types.find((t) => /^(political|locality|postal_town)/.test(t)),
    );
  }
  if (!town || town === region) {
    town = address_components.find(
      (c) =>
        c !== region &&
        c !== country &&
        !!c.types.find((t) => /^(sublocality|political|locality|postal_town)/.test(t)),
    );
  }

  let displayAddress = '';
  if (!town || town === region) {
    displayAddress = region.long_name;
  } else {
    displayAddress = `${town.long_name}, ${region.long_name}`;
  }
  displayAddress = displayAddress.trim();

  return displayAddress;
};

const initialValues = (values: Partial<Inputs> = {}): Inputs => ({
  addressInput: '',
  displayAddress: '',
  lat: undefined,
  lon: undefined,
  placeId: '',
  selectedAddress: (values.placeId && values.addressInput) || '',
  selectedDisplayAddress: (values.placeId && values.displayAddress) || '',
  ...(values ? values : {}),
});

type Autocomplete = google.maps.places.Autocomplete;

const schema = yup.object().shape({
  addressInput: yup.string().required(),
  displayAddress: yup.string().required(),
  lat: yup.number().required(),
  lon: yup.number().required(),
  placeId: yup.string().required(),
});

const BirthLocation: React.FC<PageProps> = (props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [autocomplete, setAutocomplete] = useState<Autocomplete>();
  useEffect(() => {
    const loader = new Loader({
      apiKey: 'AIzaSyAFI7-2c-JNn3HSAvZbc2q1VbsaPjlJd-E',
      libraries: ['places'],
    });
    loader.load().then(() => {
      if (!inputRef.current) {
        return;
      }
      const autocomplete = new google.maps.places.Autocomplete(
        inputRef.current as HTMLInputElement,
        {
          fields: ['address_components', 'formatted_address', 'geometry', 'place_id'],
        },
      );
      setAutocomplete(autocomplete);
    });
  }, []);

  const [editableDisplayAddress, setEditableDisplayAddress] = useState<boolean>(false);
  const displayAddressRef = useRef<HTMLInputElement | null>(null);

  const defaultValues = useMemo(
    () => initialValues(props.signupData.location || {}),
    [props.signupData.location],
  );
  const { watch, reset, control, register, handleSubmit, errors } = useForm<Inputs>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const { placeId, selectedAddress, addressInput, selectedDisplayAddress } = watch([
    'placeId',
    'selectedDisplayAddress',
    'addressInput',
    'selectedAddress',
  ]);

  useEffect(() => {
    if (!autocomplete) {
      return;
    }
    const handlePlaceChanged = () => {
      const place = autocomplete.getPlace();
      const { place_id } = place;
      if (!place_id) {
        // User didn't select a defined place. They likely
        // just hit the enter/return key inside the input.
        return;
      }

      const displayAddress = getDisplayAddressFromPlace(place);
      const { formatted_address, geometry } = place;
      const location = geometry?.location;
      const lat = location?.lat();
      const lon = location?.lng();
      if (!(!!displayAddress && typeof lat === 'number' && typeof lon === 'number')) {
        // Reset all fields
        reset(initialValues());
        return;
      }
      const addressInput =
        (inputRef?.current && (inputRef.current as HTMLInputElement)?.value) || formatted_address;
      reset(
        initialValues({
          addressInput,
          formattedAddress: formatted_address,
          displayAddress,
          placeId: place_id,
          lat,
          lon,
        }),
      );
    };
    const listener = autocomplete.addListener('place_changed', handlePlaceChanged);

    return () => listener.remove();
  }, [autocomplete, reset]);

  const onSubmit = (data: Inputs) => {
    const { addressInput, displayAddress, placeId, lat, lon } = data;
    const location: LOCATION = {
      addressInput,
      displayAddress,
      placeId,
      lat,
      lon,
    };

    props.setNewData({
      location,
    });
    props.onNext();
  };

  useEffect(() => {
    if (selectedAddress && addressInput !== selectedAddress) {
      reset(
        initialValues({
          addressInput: addressInput?.length === 1 ? addressInput : '',
          selectedAddress: '',
        }),
      );
    }
  }, [addressInput, selectedAddress]);

  const displayAddressVisible = (editableDisplayAddress || !!errors.displayAddress) && !!placeId;
  return (
    <SetupPage {...props}>
      <MainContainer>
        <SVGContainer>
          <SVG />
        </SVGContainer>
        <Header>Where {getPronoun2(props.signupData)} born?</Header>

        <FormContainer>
          <FormStyled onSubmit={handleSubmit(onSubmit)}>
            <InputContainer>
              <Controller
                control={control}
                name="addressInput"
                rules={{ required: true }}
                onFocus={() => {
                  if (inputRef.current) {
                    inputRef.current.focus();
                  }
                }}
                render={({ onChange, value, name }) => {
                  return (
                    <SearchInput
                      onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
                        if (placeId && inputRef.current) {
                          inputRef.current.select();
                        }
                      }}
                      onMouseUp={(e: React.SyntheticEvent) => {
                        if (placeId) {
                          e.preventDefault();
                        }
                      }}
                      onInput={(e: any) => {
                        if (placeId) {
                          reset(
                            initialValues({
                              addressInput: e.nativeEvent.data || '',
                              selectedAddress: '',
                            }),
                          );
                        }
                      }}
                      onKeyPress={(e: any) => {
                        if (e.nativeEvent.which === 13 && !placeId) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        onChange(e.target.value);
                      }}
                      name={name}
                      type="location"
                      value={value}
                      ref={inputRef}
                    />
                  );
                }}
              />
              {!!placeId && (
                <EditButtonContainer
                  type="button"
                  onClick={() => {
                    setEditableDisplayAddress(true);
                    if (displayAddressRef.current) {
                      displayAddressRef.current.focus();
                    }
                  }}
                  aria-label="Customize how the birthplace should be printed on the book cover"
                >
                  <PencilIcon width="24px" height="24px" color={Theme.black} />
                </EditButtonContainer>
              )}

              {errors.addressInput && <ErrorView>This field is required</ErrorView>}
              {!errors.addressInput && errors.placeId && (
                <ErrorView>Please choose a birthplace from the search results</ErrorView>
              )}
            </InputContainer>
            <Controller
              control={control}
              name="placeId"
              rules={{ required: true }}
              onFocus={() => inputRef.current?.focus()}
              render={({ value, name }) => <input type="hidden" name={name} value={value} />}
            />
            <DisplayAddressContainer
              aria-hidden={!displayAddressVisible}
              expanded={displayAddressVisible}
            >
              <DisplayAddress>
                <DisplayAddressLabel>Birthplace for Book Cover</DisplayAddressLabel>
                <DisplayAddressDescription>
                  Customize how the birthplace you selected above should be printed on the book
                  cover.
                </DisplayAddressDescription>
                <InputContainer>
                  <Controller
                    control={control}
                    name="displayAddress"
                    rules={{ required: true }}
                    onFocus={() => displayAddressRef.current?.focus()}
                    render={({ value, name, onChange }) => (
                      <DisplayAddressInput
                        tabIndex={displayAddressVisible ? undefined : -1}
                        placeholder={selectedDisplayAddress}
                        value={value}
                        name={name}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          onChange(e.target.value);
                        }}
                        ref={(el: HTMLInputElement) => {
                          displayAddressRef.current = el;
                        }}
                        type="text"
                      />
                    )}
                  />
                  {errors.displayAddress && (
                    <DisplayAddressError>This field is required</DisplayAddressError>
                  )}
                </InputContainer>
              </DisplayAddress>
            </DisplayAddressContainer>

            <input type="hidden" name="lat" ref={register} />
            <input type="hidden" name="lon" ref={register} />
            <input type="hidden" name="selectedAddress" ref={register} />
            <input type="hidden" name="selectedDisplayAddress" ref={register} />

            {/* Prevent implicit submission of the form */}
            <button type="submit" disabled style={{ display: 'none' }} aria-hidden="true" />

            <Submit />
          </FormStyled>
        </FormContainer>

        <BottomText>
          Be as accurate as possible. Start typing the name of the hospital or address{' '}
          {getPronounStatement(props.signupData)} born in if you know it. Otherwise use the town
          name.
        </BottomText>
      </MainContainer>
    </SetupPage>
  );
};
export default BirthLocation;

const SearchInput = styled(Input)`
  padding-right: 30px;
  min-height: 45px;
`;
const EditButtonContainer = styled.button`
  appearance: none;
  border: none;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  padding-left: 6px;
  padding-right: 0;
  top: 0px;
  right: 0px;
  height: 45px;
  cursor: pointer;
`;

const DisplayAddressContainer = styled.div<{ expanded: boolean }>`
  margin: 0 -1.6rem;
  max-height: ${(props) => (props.expanded ? 'none' : '0px')};
  overflow: hidden;
  color: ${Theme.black};
`;
const DisplayAddress = styled.div`
  padding: 1.6rem;
  background-color: #fff8ef;
  border-radius: 4px;
  margin-bottom: 24px;
`;
const DisplayAddressLabel = styled.div`
  font-family: 'TradeGothicNextLTPro';
  font-size: 1.5rem;
  text-transform: uppercase;
  margin-bottom: 0.6rem;
  color: #a6a29c;
  letter-spacing: 0.095em;
`;
const DisplayAddressDescription = styled.div`
  font-family: 'DomaineText';
  margin: 0.6rem 0;
  font-size: 1.5rem;
`;
const DisplayAddressInput = styled(Input)`
  margin-bottom: 0.8rem;
  min-height: 45px;
`;
const DisplayAddressError = styled(ErrorView)`
  bottom: -0.8rem;
`;
