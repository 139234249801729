import * as React from 'react';
import { ReactComponent as Face } from 'shared/assets/svgs/face.svg';
import { ReactComponent as Present } from 'shared/assets/svgs/present.svg';
import SetupPage, { PageProps, RECIPIENT, MainContainer, SVG_PROPS, HeaderContainerNoSVG } from 'shared/components/SetupPage';
import { FlexDiv, Gothic, Header, Theme } from 'shared/utils/styles';
import styled from 'styled-components';

const Who: React.FC<PageProps> = props => {
  // const data = props.signupData;

  function setReceipient(recipient: RECIPIENT) {
    props.setNewData({
      recipient,
    });
    props.onNext();
  };

  return (
    <SetupPage {...props}>
      <MainContainer>
        <HeaderContainerNoSVG style={{marginBottom: 0}}>
          <Header>Who is this book for?</Header>
        </HeaderContainerNoSVG>
        <Tile onClick={() => setReceipient(RECIPIENT.MYSELF)}>
          <Face {...SVG_PROPS}/>
          <TileText>MYSELF</TileText>
        </Tile>
        <Tile onClick={() => setReceipient(RECIPIENT.OTHER)}>
          <Present {...SVG_PROPS}/>
          <TileText>SOMEONE ELSE</TileText>
        </Tile>
      </MainContainer>
    </SetupPage>
  );
};
export default Who;

interface TileInputProps {
  onClick: () => void;
}

const Tile: React.FC<TileInputProps> = props => {
  return (
    <TileContainer {...props}>
      {props.children}
    </TileContainer>
  );
}

const TileContainer = styled(FlexDiv)`
  align-self: stretch;
  flex-direction: column;
  height: 19.4vh;
  margin-top: 24px;
  padding: 24px 0;
  box-sizing: border-box;
  border: 1px solid ${Theme.black};
  align-items: center;
  cursor: pointer;
  transition: 100ms background-color;
  &:hover {
    background-color: ${Theme.lavenderRising}
  }
`;

const TileText = styled(Gothic)`
  margin-top: 18px;
  letter-spacing: .095em;
  font-size: 15px;
  font-size: 1.5rem;
`;
