import * as React from 'react';
import styled from 'styled-components';
import { ReactComponent as MagnifyingGlass } from 'shared/assets/svgs/magnifyingGlass.svg';
import SetupPage, { MainContainer, SVG_PROPS, SVGContainer } from 'shared/components/SetupPage';
import { Header, BodyText, FlexDiv } from 'shared/utils/styles';

interface InputProps {
  onBack: () => void;
}

const Sleuthing: React.FC<InputProps> = props => {
  return (
    <SetupPage goTo={() => {}} setActiveBookIndex={() => {}} onBack={props.onBack} signupData={{}} setNewData={() => {}} onNext={() => {}}>
      <MainContainer>
        <InnerContainer>
          <MagnifyingGlass {...SVG_PROPS}/>
          <div style={{marginTop: '26px'}}>
            <Header>Time for some sleuthing!</Header>
          </div>
          <DescriptionText>{DESCRIPTION_TEXT}</DescriptionText>
        </InnerContainer>
      </MainContainer>
    </SetupPage>
  );
}

export default Sleuthing;

const DESCRIPTION_TEXT = `You will need your birth time location and date to personalize your book. Call your mom. They usually know these things.\n\nWe’ll remind you to come back later.`;

const DescriptionText = styled(BodyText)`
  text-align: center;
  margin: 24px 26px 0 26px;
  white-space: pre-line;
`;

const InnerContainer = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;
