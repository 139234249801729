import { yupResolver } from '@hookform/resolvers';
import * as React from 'react';
import { createRef, useEffect, useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ReactComponent as SVG } from 'shared/assets/svgs/dedication.svg';
import SetupPage, {
  Error,
  getPronoun2,
  Input,
  MainContainer,
  PageProps,
  SVGContainer,
  TextArea,
  BottomText,
  Submit,
  SVG_PROPS,
  InputContainer,
} from 'shared/components/SetupPage';
import { Header } from 'shared/utils/styles';
import * as yup from 'yup';
import { FormContainer, FormStyled, InputStyled } from './BirthDate';
import styled from 'styled-components';
import DedicationPreviewModal from '../DedicationPreview';
import DedicationImage from 'shared/assets/images/dedication.jpg';

const EmojiRegex = require('emoji-regex/RGI_Emoji.js');

type Inputs = {
  dedication: string;
};

let schema = yup.object().shape({
  dedication: yup.string(),
});

const MAX_CHARACTER_COUNT = 500;
const MAX_LINE_BREAKS = 8;

const normalizeText = (txt: string) => {
  // Strip emojis, weird characters
  let normalized;
  try {
    const emojiRegex = EmojiRegex();
    // https://stackoverflow.com/a/45138005
    normalized = txt
      .replace(
        /[\u{1f300}-\u{1f5ff}\u{1f900}-\u{1f9ff}\u{1f600}-\u{1f64f}\u{1f680}-\u{1f6ff}\u{2600}-\u{26ff}\u{2700}-\u{27bf}\u{1f1e6}-\u{1f1ff}\u{1f191}-\u{1f251}\u{1f004}\u{1f0cf}\u{1f170}-\u{1f171}\u{1f17e}-\u{1f17f}\u{1f18e}\u{3030}\u{2b50}\u{2b55}\u{2934}-\u{2935}\u{2b05}-\u{2b07}\u{2b1b}-\u{2b1c}\u{3297}\u{3299}\u{303d}\u{00a9}\u{00ae}\u{2122}\u{23f3}\u{24c2}\u{23e9}-\u{23ef}\u{25b6}\u{23f8}-\u{23fa}]/gu,
        '',
      )
      .replace(
        /[\u0530-\u058F\u0590-\u05FF\u0600-\u06FF\u0700-\u074F\u0750-\u077F\u0780-\u07BF\u07C0-\u07FF\u0900-\u097F\u0980-\u09FF\u0A00-\u0A7F\u0A80-\u0AFF\u0B00-\u0B7F\u0B80-\u0BFF\u0C00-\u0C7F\u0C80-\u0CFF\u0D00-\u0D7F\u0D80-\u0DFF\u0E00-\u0E7F\u0E80-\u0EFF\u0F00-\u0FFF\u1000-\u109F\u10A0-\u10FF\u1100-\u11FF\u1200-\u137F\u1380-\u139F\u13A0-\u13FF\u1400-\u167F\u1680-\u169F\u16A0-\u16FF\u1700-\u171F\u1720-\u173F\u1740-\u175F\u1760-\u177F\u1780-\u17FF\u1800-\u18AF\u1900-\u194F\u1950-\u197F\u1980-\u19DF\u19E0-\u19FF\u1A00-\u1A1F\u1B00-\u1B7F\uFB50-\uFDFF\uFE70-\uFEFF]/gu,
        '',
      )
      .replace(emojiRegex, '');
  } catch (error) {
    normalized = txt;
  }

  const lineBreaks = normalized.split('\n');
  // Limit line breaks and convert extras to spaces
  if (lineBreaks.length > MAX_LINE_BREAKS + 1) {
    const toKeep = lineBreaks.slice(0, MAX_LINE_BREAKS + 1).join('\n');
    const noLineBreaks = lineBreaks.slice(MAX_LINE_BREAKS + 1).join(' ');
    normalized = toKeep + ' ' + noLineBreaks;
  }

  return normalized.substring(0, MAX_CHARACTER_COUNT);
};

const Dedication: React.FC<PageProps> = (props) => {
  const initialDedication = useMemo(
    () => (props.signupData.dedication ? normalizeText(props.signupData.dedication) : ''),
    [props.signupData.dedication],
  );
  const { control, register, handleSubmit, errors, getValues } = useForm<Inputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      dedication: initialDedication,
    },
  });

  const onSubmit = (data: any) => {
    props.setNewData({ dedication: data.dedication });
    props.onNext();
  };

  // Note: We control the input data form react here, so that if the user goes back the text is saved.
  return (
    <SetupPage {...props}>
      <MainContainer>
        <SVGContainer>
          <SVG {...SVG_PROPS} />
        </SVGContainer>

        <Header>Dedication Page</Header>

        <FormContainerTextArea>
          <FormStyled onSubmit={handleSubmit(onSubmit)}>
            <InputContainer>
              <Controller
                control={control}
                name="dedication"
                render={({ onChange, onBlur, value, name }) => {
                  return (
                    <>
                      <TextArea
                        name={name}
                        maxLength={MAX_CHARACTER_COUNT}
                        rows={4}
                        value={value}
                        style={{ flex: 1 }}
                        onChange={(e: any) => {
                          onChange(normalizeText(e.target.value));
                        }}
                        ref={register}
                      />
                      <CharacterCount>{(value || '').length - MAX_CHARACTER_COUNT}</CharacterCount>
                    </>
                  );
                }}
              />
            </InputContainer>
            {/* <DedicationPreviewModal getValues={getValues} field='dedication'/> */}
            <SubmitContainer>
              <Submit value="Submit" />
            </SubmitContainer>
          </FormStyled>
        </FormContainerTextArea>
        <BottomText>
          This personalized gift note will be printed inside your book, right before the table of
          contents, like in the example image below.
        </BottomText>
        <ExampleDedication src={DedicationImage} alt="Example dedication page" />
      </MainContainer>
    </SetupPage>
  );
};
export default Dedication;

const ExampleDedication = styled.img`
  margin-top: 24px;
  width: 100%;
`;

const SubmitContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  margin-top: 10px;
`;

const CharacterCount = styled.div`
  font-family: 'TradeGothicNextLTPro';
  font-size: 15px;
  opacity: 0.3;
  position: absolute;
  right: 12px;
  bottom: 0px;
`;

const FormContainerTextArea = styled(FormContainer)`
  width: 100%;
  box-sizing: border-box;
  padding-left: 0;
  padding-right: 0;
`;
