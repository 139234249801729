import { yupResolver } from '@hookform/resolvers';
import * as React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ReactComponent as OpenBookSVG } from 'shared/assets/svgs/openBook.svg';
import SetupPage, {
  MainContainer,
  PageProps,
  SVGContainer,
  SVG_PROPS,
} from 'shared/components/SetupPage';
import { BodyText, FlexDiv, Header } from 'shared/utils/styles';
import Button from 'shared/components/Button';
import moment from 'moment';
import styled from 'styled-components';
import OrderDetailsModal from '../OrderDetailsModal';

const subdomains: any = {
  uk: 'uk',
  ca: 'ca',
};
const Success: React.FC<PageProps> = (props) => {
  const subdomain = subdomains[(props.orderData?.storeName || '').toLowerCase()];
  const shopLink = subdomain ? `https://${subdomain}.birthdate.co/` : 'https://birthdate.co/';
  return (
    <SetupPage {...props}>
      <MainContainer>
        <SVGContainer>
          <OpenBookSVG {...SVG_PROPS} />
        </SVGContainer>
        <Header>Hooray, we're ready to make your book!</Header>
        <DescriptionContainer>
          <Description>
            Our astrologers will get started right away. Your book will be made to order and should
            ship within 4-6 business days.
          </Description>
        </DescriptionContainer>
        <CTAContainer>
          <OrderDetailsModal {...props} />
          <ButtonLink href={shopLink}>
            <Button asTag="div" wide outline text="Shop Birthdate Co" />
          </ButtonLink>
        </CTAContainer>
      </MainContainer>
    </SetupPage>
  );
};
export default Success;

const ButtonLink = styled.a`
  appearance: none;
  text-decoration: none;
  display: block;
`;

const CTAContainer = styled.div`
  width: 100%;
  padding: 16px 0;
`;

const DescriptionContainer = styled(FlexDiv)`
  width: 100%;
  max-width: 500px;
  margin-top: 24px;
  margin-top: 2.4rem;
  justify-content: center;
`;

const Description = styled(BodyText)`
  max-width: 100%;
  text-align: center;
`;
