import * as React from 'react';
import styled from 'styled-components';
import { Theme } from '../utils/styles';

interface InputProps {
  className?: string;
}

const PageContainer: React.FC<InputProps> = props => {
  return (
    <Container className={props.className}>
      {props.children}
    </Container>
  );
}

export default PageContainer;

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: ${Theme.background};
`;
