import { yupResolver } from '@hookform/resolvers';
import * as React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ReactComponent as TimeSVG } from 'shared/assets/svgs/time.svg';
import SetupPage, {
  InputStyles,
  BottomText,
  Error,
  getPronoun2,
  getPronounStatement,
  Input,
  MainContainer,
  PageProps,
  SVGContainer,
  SVG_PROPS,
  Submit,
  InputContainer,
} from 'shared/components/SetupPage';
import { Header, Theme } from 'shared/utils/styles';
import * as yup from 'yup';
import { FormContainer, FormStyled, InputStyled } from './BirthDate';
import TimeInputPolyfill from 'react-time-input-polyfill';
import styled from 'styled-components';

const TimeInput = styled(TimeInputPolyfill)`
  width: 100%;
  min-height: 45px;
  ${InputStyles}

  ::-webkit-datetime-edit-month-field,
  ::-webkit-datetime-edit-day-field,
  ::-webkit-datetime-edit-year-field,
  ::-webkit-datetime-edit-hour-field,
  ::-webkit-datetime-edit-minute-field,
  ::-webkit-datetime-edit-meridiem-field,
  ::-webkit-datetime-edit-ampm-field,
  ::-webkit-datetime-edit-text {
    &:focus {
      background-color: #b3d5e6;
      color: ${Theme.black};
    }
    color: ${Theme.black};
  }

  ::-webkit-date-and-time-value {
    text-align: left;
  }
  &.has-empty-value:not(:invalid) {
    color: rgba(53, 34, 107, 0.3);

    &::-webkit-date-and-time-value {
      height: 100%;
      min-height: 24px;
      width: 100%;
      background-size: 69px;
      background-repeat: no-repeat;
      background-position: left center;
      background-image: url('/ios-time-placeholder.svg');
    }
  }
`;
const TimeLabel = styled.label`
  width: 100%;
`;
const TimeLabelText = styled.span`
  display: none;
`;

const formatBirthTime = (data: any) => {
  const hour = parseInt(data.hour);
  const min = parseInt(data.min);
  if (isNaN(hour) || hour > 23 || hour < 0) {
    return '';
  }
  if (isNaN(min) || min > 59 || min < 0) {
    return '';
  }

  const times = [hour < 10 ? `0${hour}` : `${hour}`, min < 10 ? `0${min}` : `${min}`];

  return times.join(':');
};
const parseBirthTime = (time: string): any => {
  const times = time.split(':');
  const hour = parseInt(times[0]);
  const min = parseInt(times[1]);

  if (isNaN(hour) || isNaN(min) || hour > 23 || hour < 0 || min > 59 || min < 0) {
    throw new TypeError('Please enter a birthtime, like 05:30 PM.');
  }

  return {
    hour,
    min,
  };
};

type Inputs = {
  time: string;
};

let schema = yup.object().shape({
  time: yup.string().required(),
});

const BirthTime: React.FC<PageProps> = (props) => {
  const { control, register, handleSubmit, setError, errors, watch } = useForm<Inputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      time: formatBirthTime(props.signupData),
    },
  });

  const dateInputVal = watch('time');

  const onSubmit = (data: any) => {
    try {
      const newData = parseBirthTime(data.time);
      props.setNewData(newData);
      props.onNext();
    } catch (error) {
      setError('time', error);
    }
  };

  return (
    <SetupPage {...props}>
      <MainContainer>
        <SVGContainer>
          <TimeSVG {...SVG_PROPS} />
        </SVGContainer>
        <Header>What time {getPronoun2(props.signupData)} born?</Header>

        <FormContainer>
          <FormStyled onSubmit={handleSubmit(onSubmit)}>
            <InputContainer>
              <Controller
                control={control}
                name="time"
                render={({ onChange, onBlur, value, name }) => {
                  return (
                    <TimeLabel>
                      <TimeLabelText>Birth time</TimeLabelText>
                      <TimeInput
                        name={name}
                        type="time"
                        value={value}
                        onChange={(e: any) => {
                          onChange(e.value);
                        }}
                        // forcePolyfill={true}
                        data-placeholder="--:-- --"
                        className={!!dateInputVal ? 'has-value' : 'has-empty-value'}
                      />
                    </TimeLabel>
                  );
                }}
              />
              {errors.time && <Error>Please enter a birthtime, like 05:30 PM.</Error>}
            </InputContainer>

            <Submit />
          </FormStyled>
        </FormContainer>

        <BottomText>
          If you don't know this, our astrologers recommend using the sunrise time on the day{' '}
          {getPronounStatement(props.signupData)} born. You can easily find this on Google (for
          example, search "sunrise in Los Angeles, CA on March 5, 1989")
        </BottomText>
      </MainContainer>
    </SetupPage>
  );
};
export default BirthTime;
