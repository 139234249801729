import { createGlobalStyle } from 'styled-components';
import DomaineTextRegular from 'shared/assets/fonts/DomaineText-Regular.otf';
import DomaineTextLight from 'shared/assets/fonts/DomaineText-Light.otf';
import DomaineTextLightItalic from 'shared/assets/fonts/DomaineText-LightItalic.otf';
import DomaineTextMedium from 'shared/assets/fonts/DomaineText-Medium.otf';
import TradeGothicNextLTProLight from 'shared/assets/fonts/TradeGothicNextLTPro-Light.otf';
import TradeGothicNextLTProRegular from 'shared/assets/fonts/TradeGothicNextLTPro-Regular.woff';
import TradeGothicNextLTProBold from 'shared/assets/fonts/TradeGothicNextLTPro-Bold.woff';
import { ANIMATION_TIME } from 'project/flow/Flow';
import { Theme } from './styles';

export default createGlobalStyle`
	html {
		/* 10px gives us convenient rem units */
		font-size: 10px;

		@media (max-width: 380px) {
			font-size: 9px;
		}
	}

	body {
		margin: 0;
    color: ${Theme.black};
	}

  html, body, #root {
    width: 100%;
    height: 100%;
  }

  /* Show placeholders for date and time, since they werent showing on iOS */
  /*input[type="date"]:not(.has-value):before{
    content: attr(data-placeholder);
    width: 100%;
  }

  input[type="time"]:not(.has-value):before{
    content: attr(data-placeholder);
    width: 100%;
  }*/

  .example-enter.right {
    transform: translate3d(100vw, 0, 0);
    position: absolute;
    z-index: 1;
  }

  .example-enter.example-enter-active.right {
    transition: transform ${ANIMATION_TIME}ms ease;
    transform: translate3d(0, 0, 0);
  }

  .example-enter.left {
    transform: translate3d(-100vw, 0, 0);
    position: absolute;
    z-index: 1;
  }

  .example-enter.example-enter-active.left {
    transition: transform ${ANIMATION_TIME}ms ease;
    transform: translate3d(0, 0, 0);
  }

  .example-leave {
  }

  .example-leave.example-leave-active {
    transition: opacity ${ANIMATION_TIME}ms ease-in;
  }

  @font-face {
    font-family: 'DomaineText';
    src: url(${DomaineTextRegular});
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'DomaineText';
    src: url(${DomaineTextLight});
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: 'DomaineText';
    src: url(${DomaineTextLightItalic});
    font-weight: 300;
    font-style: italic;
  }
  @font-face {
    font-family: 'DomaineText';
    src: url(${DomaineTextMedium});
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'TradeGothicNextLTPro';
    src: url(${TradeGothicNextLTProLight});
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'TradeGothicNextLTPro';
    src: url(${TradeGothicNextLTProRegular}) format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'TradeGothicNextLTPro';
    src: url(${TradeGothicNextLTProBold}) format('woff');
    font-weight: bold;
    font-style: normal;
  }
`;
