import * as React from 'react';
import { useEffect } from 'react';
import { ReactComponent as Check } from 'shared/assets/svgs/check.svg';
import { ReactComponent as Arrow } from 'shared/assets/svgs/rightArrow.svg';
import { ReactComponent as ArrowDark } from 'shared/assets/svgs/rightArrowDark.svg';
import Button from 'shared/components/Button';
import PageContainer from 'shared/components/PageContainer';
import { PageProps } from 'shared/components/SetupPage';
import { BodyText, FlexDiv, Header, Theme } from 'shared/utils/styles';
import styled from 'styled-components';
import ReactLoading from 'react-loading';
import BookThumb from 'shared/assets/images/book-thumb.png';
import { formatName } from './flow/ConfirmBook';
import Success from './flow/Success';
import OrderDetailsModal from './OrderDetailsModal';

const LandingPage: React.FC<PageProps> = (props) => {
  const isLoading = props.orderDataRequest ? props.orderDataRequest.isLoading : true;
  const { quantity = 0, complete = false, bookInfo = [] } = props.orderData || {};
  if (complete) {
    return <Success {...props} disableBack={true} />;
  }

  let orderDetails;
  if (isLoading) {
    orderDetails = (
      <OrderDetailsLoading>
        <ReactLoading type="spinningBubbles" color="#0F2756" />
      </OrderDetailsLoading>
    );
  } else if (quantity === 1) {
    orderDetails = (
      <ButtonContainer>
        <Button text="continue" onClick={props.onNext}>
          <Arrow />
        </Button>
        <OrderDetailsModal simple {...props} />
      </ButtonContainer>
    );
  } else {
    orderDetails = (
      <>
        <OrderDetailsDescription>
          Select a book from your order below to continue
        </OrderDetailsDescription>
        <OrderDetailsList>
          {bookInfo.map((book, i) => (
            <OrderDetailsListItem
              key={i}
              onClick={() => {
                props.setActiveBookIndex(i);
                props.onNext();
              }}
            >
              <OrderDetailsListItemImage src={BookThumb} alt="Birthdate Book" />
              <OrderDetailsListItemText>
                <OrderDetailsListItemTitle>Birthdate Book</OrderDetailsListItemTitle>
                {book && (
                  <OrderDetailsListItemDescription>
                    {formatName(book)}
                  </OrderDetailsListItemDescription>
                )}
              </OrderDetailsListItemText>
              {book ? <Check /> : <ArrowDark />}
            </OrderDetailsListItem>
          ))}
        </OrderDetailsList>
        <OrderDetailsModal simple {...props} />
      </>
    );
  }

  return (
    <PageContainer className={props.isGoingBack ? 'left' : 'right'}>
      <HeaderContainer>
        <Header>Customize Birthdate Book</Header>
      </HeaderContainer>
      {props.orderDataRequest?.error ? (
        <DescriptionContainer>
          <ErrorMessage>
            We weren't able to load your order. Please try again later or check your order status.
          </ErrorMessage>
        </DescriptionContainer>
      ) : (
        <>
          <DescriptionContainer>
            <Description>
              Congratulations on your purchase. To receive your customized book, our astrologers
              need some information:
            </Description>
          </DescriptionContainer>
          <WhiteBoxContainer>
            <WhiteBox>
              <WhiteBoxRow>
                <Check />
                <WhiteBoxText>Name of Recipient</WhiteBoxText>
              </WhiteBoxRow>
              <WhiteBoxRow>
                <Check />
                <WhiteBoxText>Birth Time & Date</WhiteBoxText>
              </WhiteBoxRow>
              <WhiteBoxRow>
                <Check />
                <WhiteBoxText>Birth Location</WhiteBoxText>
              </WhiteBoxRow>
            </WhiteBox>
          </WhiteBoxContainer>
          <OrderDetailsContainer>{orderDetails}</OrderDetailsContainer>
        </>
      )}
    </PageContainer>
  );
};

export default LandingPage;

const HeaderContainer = styled(FlexDiv)`
  box-sizing: border-box;
  margin-top: 10rem;
  width: 100%;
  padding: 0 16px;
  padding: 0 1.6rem;
`;

const DescriptionContainer = styled(FlexDiv)`
  box-sizing: border-box;
  width: 100%;
  margin-top: 24px;
  margin-top: 2.4rem;
  justify-content: center;
  padding: 0 16px;
  padding: 0 1.6rem;
`;

const Description = styled(BodyText)`
  text-align: center;
`;

const ErrorMessage = styled(BodyText)`
  font-size: 20px;
  margin: 48px auto;
  max-width: 500px;
`;

const WhiteBoxContainer = styled(FlexDiv)`
  margin-top: 48px;
  margin-top: 4.8rem;
  width: 100%;
  justify-content: center;
`;

const WhiteBox = styled(FlexDiv)`
  background-color: white;
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.11);
  border-radius: 4px;
  padding: 24px;
  box-sizing: border-box;
  flex-direction: column;
`;

// margin: 0 24px;
const WhiteBoxRow = styled(FlexDiv)`
  flex: 1;
  margin: 1rem;
  align-items: center;
`;

const WhiteBoxText = styled(BodyText)`
  margin-left: 18px;
`;

const OrderDetailsContainer = styled(FlexDiv)`
  width: 100%;
  margin: 48px auto;
  padding: 0 24px;
  max-width: 500px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
`;

const OrderDetailsDescription = styled(BodyText)`
  max-width: 100%;
  margin: 0 14px 14px 14px;
`;
const OrderDetailsLoading = styled.div`
  margin: 48px auto;
`;

const OrderDetailsList = styled(FlexDiv)`
  flex-direction: column;
  max-width: 100%;
`;
const OrderDetailsListItem = styled(FlexDiv)`
  flex-direction: row;
  align-items: center;
  padding: 20px 0;
  font-family: 'DomaineText';
  cursor: pointer;
  &:not(:first-child) {
    border-top: 1px solid ${Theme.gray};
  }
`;

const OrderDetailsListItemImage = styled.img`
  max-width: 73px;
  margin-right: 14px;
`;
const OrderDetailsListItemTitle = styled.div`
  font-size: 20px;
  text-align: left;
`;
const OrderDetailsListItemDescription = styled.div`
  text-align: left;
  font-size: 13px;
  margin-top: 2px;
  // font-family: 'TradeGothicNextLTPro';
  // letter-spacing: 0.095em;
  // text-transform: uppercase;
`;
// const OrderDetailsListItemText = styled.div`
//   font-family: 'DomaineText';
//   white-space: nowrap;
//   overflow: hidden;
//   text-overflow: ellipsis;
// `;
const OrderDetailsListItemText = styled(FlexDiv)`
  flex-direction: column;
  margin-right: 14px;
  flex: 1 1 auto;
  // white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;
`;

const ButtonContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
`;
