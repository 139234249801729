import * as React from 'react';
import styled from 'styled-components';

export const Theme = {
  background: '#FFFCF8',
  lightBlack: '#0F2756',
  black: '#0F2756',
  primaryColor: '#0F2756',
  gray: '#C4C4C4',
  grayBlue: '#C2BECC',
  blue: '#3784CC',
  lightBeige: '#FFFCF8',
  beige: '#F3EADC',
  ctaGreen: '#EBE3FF',
  // black: '#12092B',
  carouselFilled: '#1C3F73',
  carouselUnfilled: '#241919',
  section1: '#F3EADC',
  white: '#FFFFFF',

  beigeSun: '#FDE9CF',
  blueMoon: '#B3D5E6',
  greenJupiter: '#CEE6DD',
  greyMercury: '#D8D4D3',
  lavenderRising: '#EADEEC',
  pinkVenus: '#F9E4ED'
};

export const FlexDiv = styled.div`
  display: flex;
`;

export const FlexDivCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MyText = styled.div`
  font-family: 'DomaineText';
  color: ${Theme.black};
  font-weight: 400;
`;

export const Gothic = styled.div`
  font-family: 'TradeGothicNextLTPro';
  color: ${Theme.black};
  font-weight: 400;
`;

export const Header: React.FC = props => (
  <HeaderContainer>
    <HeaderText>{props.children}</HeaderText>
  </HeaderContainer>
);

const HeaderContainer = styled(FlexDiv)`
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const HeaderText = styled(MyText)`
  text-align: center;
  font-size: 38px;
  font-size: 3.8rem;
  font-weight: 400;
  color: ${Theme.lightBlack};
  line-height: 48px;
  line-height: 4.8rem;
`;

export const BodyText = styled(MyText)`
  line-height: 20px;
  line-height: 2rem;
  font-size: 15px;
  font-size: 1.5rem;
  margin: 0 34px;
  text-align: center;
`;
