import * as React from 'react';
import { useState } from 'react';
import SetupPage, { HeaderContainerNoSVG, PageProps } from 'shared/components/SetupPage';
import styled from 'styled-components';
import { Header, BodyText } from 'shared/utils/styles';
import Button from 'shared/components/Button';
import moment from 'moment';

export function formatName(data: any) {
  let { firstName = '', middleName = '', lastName = '' } =  data;
  firstName = firstName.trim()
  middleName = middleName.trim()
  lastName = lastName.trim()
  const names = []
  if (firstName) {
    names.push(firstName);
  }
  if (middleName) {
    names.push(middleName);
  }
  if (lastName) {
    names.push(lastName);
  }

  if (!names.length) {
    return;
  }
  return names.join(' ');
}
export function formatBirthdate(data: any) {
  const {
    day, month, year
  } = data;
  if (!day || !month || !year) {
    return;
  }
  const birthdate = moment();
  birthdate.month(month - 1);
  birthdate.year(year);
  birthdate.date(day);
  return birthdate.format('MMMM D, YYYY');
}
export function formatBirthtime(data: any) {
  let {
    hour, min
  } = data;
  if ((typeof hour === 'undefined') || (typeof min === 'undefined')) {
    return;
  }
  let meridian = 'PM';
  if (hour > 12) {
    meridian = 'PM';
    hour -= 12;
  } else if (hour < 12) {
    meridian = 'AM';
    if (hour == 0) {
      hour = 12;
    }
  }

  if (min < 10) {
    min = `0${min}`;
  }
  return `${hour}:${min} ${meridian}`;
}

const ConfirmBook: React.FC<PageProps> = (props) => {
  const [fetchState, setFetchState]: any = useState({
    isLoading: false,
    success: false,
    error: null
  })
  const { signupData } = props

  const submitBookData = () => {
    if (!props.confirmBook) {
      return
    }

    setFetchState({
      isLoading: true,
      success: false,
      error: null
    })

    props.confirmBook(signupData)
      .then((result: any) => {
        setFetchState({
          isLoading: false,
          success: true,
          error: null
        })
      })
      .catch((error: any) => {
        setFetchState({
          isLoading: false,
          success: false,
          error: 'Something went wrong. Please try again later.'
        })
      })
  }

  return (
    <SetupPage {...props}>
      <Container>
        <InnerWrapper>
          <HeaderContainerNoSVG>
            <Header>Let's make sure everything looks right!</Header>
          </HeaderContainerNoSVG>
          <Disclaimer>
            Books are made to order, so please make sure all this info is correct and
            double-checked. You will not be able to change this after your book is sent for
            processing.
          </Disclaimer>

          <FieldsContainer>
            <Field>
              <FieldLabel>NAME</FieldLabel>
              <FieldPreview>{formatName(signupData)}</FieldPreview>
            </Field>
            <Field>
              <FieldLabel>BIRTHDATE</FieldLabel>
              <FieldPreview>{formatBirthdate(signupData)}</FieldPreview>
            </Field>
            <Field>
              <FieldLabel>BIRTHTIME</FieldLabel>
              <FieldPreview>{formatBirthtime(signupData)}</FieldPreview>
            </Field>
            <Field>
              <FieldLabel>BIRTHPLACE</FieldLabel>
              <FieldPreview>{signupData.location?.displayAddress}</FieldPreview>
              {signupData.location?.addressInput !== signupData.location?.displayAddress && (
                <FieldDescription>
                  This is the birthplace associated with the address you selected:{' '}
                  {signupData.location?.addressInput}
                </FieldDescription>
              )}
            </Field>
            <Field>
              <FieldLabel>DEDICATION</FieldLabel>
              {signupData.dedication ? (
                <FieldPreview>{signupData.dedication}</FieldPreview>
              ) : (
                <FieldDescription style={{marginTop: '0px'}}>Dedication page will be left blank</FieldDescription>
              )}
            </Field>

            { fetchState.error && <ErrorMessage>Something went wrong. Please try again later</ErrorMessage>}
          </FieldsContainer>
          <CTAContainer>
            <Button wide onClick={() => {props.goTo('1', true)}} text='Edit Book'/>
            <Button wide outline text='Submit' onClick={fetchState.isLoading ? undefined : submitBookData}/>
          </CTAContainer>
        </InnerWrapper>
      </Container>
    </SetupPage>
  );
};
export default ConfirmBook;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  white-space: pre-wrap;
  text-align: left;
`;

const InnerWrapper = styled.div`
  width: 100%;
  max-width: 500px;
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Field = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  background-color: #fff8ef;
  border-radius: 4px;
  margin-bottom: 24px;
`;
const FieldLabel = styled.div`
  font-family: 'TradeGothicNextLTPro';
  font-size: 13px;
  text-transform: uppercase;
  margin-bottom: 6px;
  color: #a6a29c;
  letter-spacing: 0.095em;
`;
const FieldPreview = styled.div`
  font-family: 'DomaineText';
  font-weight: 400;
  font-size: 20px;
  overflow-wrap: break-word;
`;
const FieldDescription = styled.div`
  font-family: 'DomaineText';
  margin-top: 4px;
  font-weight: 300;
  font-style: italic;
  font-size: 12px;
  opacity: 0.5;
`;
const Disclaimer = styled(BodyText)`
  background-color: #eb7644;
  color: white;
  padding: 16px;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 24px;
`;
const CTAContainer = styled.div`
  width: 100%;
  padding: 16px 0;
`;
const ErrorMessage = styled(BodyText)`
  color: red;
`;