import * as React from 'react';
import { FlexDiv, MyText, Theme } from 'shared/utils/styles';
import styled from 'styled-components';

interface InputProps {
  text: string;
  wide?: boolean;
  outline?: boolean;
  onClick?: () => void;
  asTag?: 'button' | 'div';
}

const Button: React.FC<InputProps> = (props) => {
  const { onClick, text, children, asTag, ...rest } = props;
  return (
    <Container as={asTag || 'button'} onClick={onClick}>
      <InnerContainer {...rest}>
        <ButtonText {...rest}>{text}</ButtonText>
        {children}
      </InnerContainer>
    </Container>
  );
};
export default Button;


const Container = styled(FlexDiv)`
  width: 100%;
  justify-content: center;
  margin-bottom: 1rem;
  padding: 0;
  cursor: pointer;
  border: none;
  background-color: none;
  background: none;
`;


const ButtonText = styled(MyText)<any>`
  position: relative;
  margin-right: 12px;
  font-family: 'TradeGothicNextLTPro';
  font-size: 15px;
  letter-spacing: .095em;
  text-transform: uppercase;
  &:last-child {
    margin-right: 0px;
  }
`;

const InnerContainer = styled(FlexDiv)<any>`
  width: 100%;
  background-color: ${p => p.outline ? 'transparent' : Theme.black};
  justify-content: center;
  align-items: center;
  ${p => p.outline && `
    border: solid 1px ${Theme.black};
  `}
  border-radius: 4px;
  box-shadow: 2px 2px 0 ${Theme.beigeSun};
  padding: 2rem;
  cursor: pointer;
  transition: 100ms background-color;
  ${ButtonText} {
    color: ${p => p.outline ? Theme.black : Theme.background};
  }
  &:hover {
    background-color: ${Theme.blue};
    color: ${Theme.background};
    ${ButtonText} {
      color: ${Theme.background};
    }
    ${p => p.outline && `
      border: solid 1px ${Theme.blue};
    `}
  }
`;
