import * as React from 'react';
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ReactComponent as NameSVG } from 'shared/assets/svgs/name.svg';
import SetupPage, {
  ErrorView,
  Form,
  Input,
  MainContainer,
  PageProps,
  RECIPIENT,
  SVGContainer,
  SVG_PROPS,
  Submit,
  InputContainer,
} from 'shared/components/SetupPage';
import { Header } from 'shared/utils/styles';
import styled from 'styled-components';

type Inputs = {
  firstName: string;
  middleName: string;
  lastName: string;
};

const nameValidation = {
  required: true,
  validate: (value?: string) => (value || '').trim().length > 0 || 'This field is required',
};

const validateNameLength = (values: any) => {
  const { firstName = '', middleName = '', lastName = '' } = values;
  const fullName = [firstName.trim(), middleName.trim(), lastName.trim()]
    .filter((n) => !!n)
    .join(' ');
  if (fullName.length > 35) {
    return `Please keep full name under 35 letters so it fits on the book's cover.`;
  } else {
    return null;
  }
};

const Name: React.FC<PageProps> = (props) => {
  const [maxLengthError, setMaxLengthError]: [any, any] = useState(null);
  const { watch, register, handleSubmit, errors } = useForm<Inputs>();

  const values = watch(['firstName', 'middleName', 'lastName']);
  useEffect(() => {
    setMaxLengthError(validateNameLength(values));
  }, [values]);

  const onSubmit = (data: any) => {
    const err = validateNameLength(data);
    setMaxLengthError(err);

    if (!err) {
      props.setNewData(data);
      props.onNext();
    }
  };

  const data = props.signupData;
  const isOther = data?.recipient === RECIPIENT.OTHER;

  return (
    <SetupPage {...props}>
      <MainContainer>
        <SVGContainer>
          <NameSVG {...SVG_PROPS} />
        </SVGContainer>
        <Header>What is {isOther ? 'their' : 'your'} name?</Header>

        <Form onSubmit={handleSubmit(onSubmit)}>
          <NameInputContainer>
            <Input
              name="firstName"
              placeholder="First Name"
              ref={register({ required: true })}
              defaultValue={data.firstName}
            />
            {errors.firstName && (
              <ErrorContainer>
                {errors.firstName && <ErrorMessage>This field is required.</ErrorMessage>}
              </ErrorContainer>
            )}
          </NameInputContainer>

          <NameInputContainer>
            <Input
              name="middleName"
              placeholder="Middle Name"
              defaultValue={data.middleName}
              ref={register}
            />
          </NameInputContainer>

          <NameInputContainer>
            <Input
              name="lastName"
              placeholder="Last Name"
              defaultValue={data.lastName}
              ref={register}
            />

            {maxLengthError && (
              <ErrorContainer>
                <MaxLengthError>{maxLengthError}</MaxLengthError>
              </ErrorContainer>
            )}
          </NameInputContainer>

          <Submit />
        </Form>
      </MainContainer>
    </SetupPage>
  );
};
export default Name;

const NameInputContainer = styled(InputContainer)`
  flex-direction: column;
`;

const ErrorContainer = styled.div`
  margin-top: -20px;
  margin-left: 8px;
  min-height: 20px;
`;

const ErrorMessage = styled(ErrorView)`
  position: relative;
  bottom: auto;
  left: auto;
  margin-bottom: 0.6rem;
`;
const MaxLengthError = styled(ErrorMessage)`
  display: block;
  margin-bottom: 1.6rem;
`;
