import * as React from 'react';
import { useState } from 'react';
import Button from 'shared/components/Button';
import SetupPage, { MainContainer, PageProps, RECIPIENT, HeaderContainerNoSVG, VERTICAL_MARGIN, HORIZONTAL_MARGIN } from 'shared/components/SetupPage';
import { Gothic, Header, Theme } from 'shared/utils/styles';
import { IS_DEV } from 'shared/utils/util';
import styled from 'styled-components';
import Sleuthing from './Sleuthing';

const InfoConfirm: React.FC<PageProps> = props => {
  const [showSleuthing, setShowSleuthing] = useState(IS_DEV ? false : false);
  const data = props.signupData;
  const isOther = data?.recipient === RECIPIENT.OTHER;

  return (
    <SetupPage {...props}>
      <MainContainer>
        <HeaderContainerNoSVG>
          <Header>Do you know {isOther ? 'their' : 'your'} birth date, time, and location?</Header>
        </HeaderContainerNoSVG>
        <Button wide onClick={() => {
          props.onNext()
        }} text='Yes, I have these handy'/>
        <Button wide outline text='No, not at the moment' onClick={() => setShowSleuthing(true)}/>
        <DontKnow onClick={() => setShowSleuthing(true)}>Don't Know This?</DontKnow>
      </MainContainer>
      {showSleuthing &&
        <FullPageOverlay>
          <Sleuthing onBack={() => setShowSleuthing(false)}/>
        </FullPageOverlay>
      }
    </SetupPage>
  );
};
export default InfoConfirm;

const DontKnow = styled(Gothic)`
  margin-top: 48px;
  font-size: 15px;
  color: ${Theme.black};
  opacity: 0.5;
`;

const FullPageOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
`;
