import * as React from 'react';
import { ReactComponent as BackArrow } from 'shared/assets/svgs/backArrow.svg';
import { FlexDiv, Theme, MyText, FlexDivCenter, BodyText } from 'shared/utils/styles';
import styled from 'styled-components';
import PageContainer from './PageContainer';
import Button from './Button';

const SetupPage: React.FC<PageProps> = (props) => {
  return (
    <PageContainer className={props.isGoingBack ? 'left' : 'right'}>
      <InnerContainer>
        <CutBorder />
        {props.children}
        <HeaderContainer>
          <Header>
            <HeaderText>CREATE YOUR BOOK</HeaderText>
          </Header>
        </HeaderContainer>
        {props.disableBack ? null : (
          <BackArrowContainer onClick={props.onBack}>
            <BackArrow />
          </BackArrowContainer>
        )}
      </InnerContainer>
    </PageContainer>
  );
};
export default SetupPage;

const CutBorder = () => (
  <div style={{ zIndex: -5 }}>
    <div
      style={{
        position: 'absolute',
        bottom: '10px',
        left: '0',
        top: '10px',
        borderLeft: `1px solid ${Theme.gray}`,
      }}
    />
    <div
      style={{
        position: 'absolute',
        bottom: '10px',
        right: '0',
        top: '10px',
        borderRight: `1px solid ${Theme.gray}`,
      }}
    />
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: '10px',
        right: '10px',
        borderTop: `1px solid ${Theme.gray}`,
      }}
    />
    <div
      style={{
        position: 'absolute',
        bottom: 0,
        left: '10px',
        right: '10px',
        borderBottom: `1px solid ${Theme.gray}`,
      }}
    />
    <svg
      width="10px"
      height="10px"
      viewBox="0 0 73 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ position: 'absolute', top: 0, left: 0 }}
    >
      <path
        d="M0 72L72 0"
        vector-effect="non-scaling-stroke"
        stroke={Theme.gray}
        stroke-width="1"
      />
    </svg>
    <svg
      width="10px"
      height="10px"
      viewBox="0 0 73 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ position: 'absolute', bottom: 0, right: 0 }}
    >
      <path
        d="M0 72L72 0"
        vector-effect="non-scaling-stroke"
        stroke={Theme.gray}
        stroke-width="1"
      />
    </svg>
    <svg
      width="10px"
      height="10px"
      viewBox="0 0 73 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ position: 'absolute', top: 0, right: 0 }}
    >
      <path
        d="M0 0L72 72"
        vector-effect="non-scaling-stroke"
        stroke={Theme.gray}
        stroke-width="1"
      />
    </svg>
    <svg
      width="10px"
      height="10px"
      viewBox="0 0 73 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ position: 'absolute', bottom: 0, left: 0 }}
    >
      <path
        d="M0 0L72 72"
        vector-effect="non-scaling-stroke"
        stroke={Theme.gray}
        stroke-width="1"
      />
    </svg>
  </div>
);

export interface PageProps {
  signupData: SignupData;
  orderData?: OrderData;
  orderDataRequest?: FetchOrderRequest;
  setActiveBookIndex: (index: number) => void;
  setNewData: (data: Partial<SignupData>) => void;
  goTo: (path: string, backwards?: boolean) => void;
  onBack: () => void;
  onNext: () => void;
  active?: boolean;
  isGoingBack?: boolean;
  confirmBook?: (data: SignupData) => any;
  disableBack?: boolean;
}

export interface SignupData {
  recipient?: RECIPIENT;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  date?: Date;
  time?: string;
  location?: LOCATION;
  dedication?: string;
  hour?: number;
  min?: number;
  year?: number;
  day?: number;
  month?: number;
}

export interface OrderData {
  storeName?: string;
  complete?: boolean;
  quantity?: number;
  bookInfo?: Array<SignupData>;
}
export interface FetchOrderRequest {
  isLoading?: boolean;
  error?: any;
  data?: any;
}

export interface BIRTHDAY {
  hour: number;
  min: number;
  year: number;
  day: number;
  month: number;
}

export interface SignupDataPayload {
  firstName: string;
  middleName: string;
  lastName: string;
  birthday: BIRTHDAY;
  location: LOCATION;
  dedication?: string;
}

export interface LOCATION {
  displayAddress?: string;
  lat?: number;
  lon?: number;
  placeId?: string;
  addressInput?: string;
  formattedAddress?: string;
}

export enum RECIPIENT {
  MYSELF,
  OTHER,
}

export const makeEmptySignupData = () => ({
  location: {},
});

// were you, was Phil, were they
export function getPronoun2(data: SignupData) {
  const isOther = data?.recipient === RECIPIENT.OTHER;
  const name = data?.firstName;
  return !isOther ? 'were you' : !!name ? 'was ' + name : 'were they';
}

export function getPronounStatement(data: SignupData) {
  const isOther = data?.recipient === RECIPIENT.OTHER;
  const name = data?.firstName;
  return !isOther ? 'you were' : !!name ? `${name} was` : 'they were';
}

export function getPossessive(data: SignupData) {
  const isOther = data?.recipient === RECIPIENT.OTHER;
  return !isOther ? 'your' : 'their';
}

const BackArrowContainer = styled(FlexDiv)`
  position: absolute;
  top: 4.5%;
  left: -4px;
  width: 48px;
  height: 45px;
  background-color: ${Theme.background};
  border: 1px solid ${Theme.grayBlue};
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 100ms background-color;
  &:hover {
    background-color: ${Theme.lavenderRising};
  }
`;

const HeaderContainer = styled(FlexDivCenter)`
  position: absolute;
  top: -12px;
  left: 0;
  right: 0;
`;

export const HeaderContainerNoSVG = styled.div`
  margin-top: 138px;
  margin-top: 13.8rem;
  margin-bottom: 24px;
`;

const Header = styled.div`
  padding 5px 12px 0 12px;
  background-color: ${Theme.background};
`;

const HeaderText = styled(MyText)`
  font-size: 12px;
  letter-spacing: 0.13em;
`;

export const HORIZONTAL_MARGIN = 8;
export const VERTICAL_MARGIN = 13;
const InnerContainer = styled.div`
  position: relative;
  width: calc(100% - ${HORIZONTAL_MARGIN * 2 + 4}px);
  min-height: calc(100% - ${VERTICAL_MARGIN * 2 + 4}px);
  box-sizing: border-box;
  margin: ${VERTICAL_MARGIN}px auto;
  padding: 4rem;
  max-width: 600px;
`;

export const MainContainer = styled(FlexDiv)`
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const BottomText = styled(BodyText)`
  margin-top: 48px;
  @media (max-width: 640px) {
    margin-left: 0;
    margin-right: 0;
  }
`;

export const SVGContainer = styled.div`
  width: 164px;
  width: 16.4rem;
  margin-bottom: 38px;
  margin-top: 63px;
  margin-top: 6.3rem;
`;

export const SVG_PROPS = {
  style: {
    width: '100%',
  },
};

export const Form = styled.form.attrs({
  mode: 'all',
})`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  width: 100%;
`;

export const InputStyles = `
  padding: 1rem 0;
  box-sizing: border-box;
  margin-bottom: 24px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid ${Theme.lightBlack};
  font-family: 'TradeGothicNextLTPro';
  color: ${Theme.black};
  font-weight: 400;
  // Temporary fix to get around iOS zoom
  font-size: 16px;
  width: 100%;

  border-radius: 0;
  appearance: none;
  -webkit-appearance: none;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: rgba(53, 34, 107, 0.3);
  }
  :-ms-input-placeholder {
    color: rgba(53, 34, 107, 0.3);
  }
  &:focus:not(:focus-visible) {
    outline: none;
  }
`;

export const Input = styled.input`
  ${InputStyles}
`;

interface SubmitProps {
  value?: string;
}
export const Submit: React.FC<SubmitProps> = (props) => {
  return <Button text="Submit" />;
};
/*export const Submit = styled.input.attrs(p => ({
  type: 'submit',
  value: p.value ?? <MyText>Hello</MyText>,
}))<any>`
  box-sizing: border-box;
  padding: 2rem;
  background-color: ${Theme.primaryColor};
  box-shadow: 2px 2px 0 ${Theme.beigeSun};
  cursor: pointer;
  transition: 100ms background-color;
  &:hover {
    background-color: ${Theme.blue}
  }
  border: 0;
  font-family: 'TradeGothicNextLTPro';
  color: ${Theme.background};
  letter-spacing: .095em;
  border-radius: 4px;
  font-weight: bold;
  font-size: 12px;
  font-size: 1.2em;
  text-transform: uppercase;
  margin-top: 8px;

  -webkit-appearance: none;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: rgba(53, 34, 107, 0.2);
  }
  :-ms-input-placeholder {
    color: rgba(53, 34, 107, 0.2);
  }
`;*/

export const TextArea = styled.textarea`
  box-sizing: border-box;
  margin-bottom: 24px;
  background-color: white;
  border: none;
  font-family: 'DomaineText';
  color: black;
  padding: 1rem;
  font-size: 16px;
  line-height: 20px;
  font-weight: normal;
  position: relative;
  width: 100%;
  border: 1px solid ${Theme.gray};
  border-radius: 4px;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: rgba(53, 34, 107, 0.2);
  }
  :-ms-input-placeholder {
    color: rgba(53, 34, 107, 0.2);
  }
`;

export const Error = (props: any) => <ErrorView>{props.children}</ErrorView>;

export const ErrorView = styled.span`
  position: absolute;
  bottom: 8px;
  bottom: 0.8rem;
  color: red;
  left: 8px;
  font-family: 'TradeGothicNextLTPro';
  font-size: 1rem;
`;

export const InputContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex: 1;
`;
