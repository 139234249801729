import LandingPage from 'project/LandingPage';
import { CSSTransitionGroup } from 'react-transition-group';
import * as React from 'react';
import { useState, useEffect, useMemo } from 'react';
import { makeEmptySignupData, SignupData } from 'shared/components/SetupPage';
import { Theme } from 'shared/utils/styles';
import { IS_DEV } from 'shared/utils/util';
import BirthDate from './BirthDate';
import InfoConfirm from './InfoConfirm';
import ConfirmBook from './ConfirmBook';
import Name from './Name';
import Who from './Who';
import BirthTime from './BirthTime';
import BirthLocation from './BirthLocation';
import Dedication from './Dedication';
import Success from './Success';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import QueryString from 'query-string';
import useFetch from 'react-fetch-hook';

interface InputProps {}

const Flow: React.FC<InputProps> = (props) => {
  const [pageIx, setPageIx] = useState(IS_DEV ? 0 : 0);
  const [queryParams, setQueryParams] = useState(QueryString.parse(window.location.search));
  const [hasMounted, setHasMounted] = useState(false);
  const [allSignupData, setSignupData] = useState([makeEmptySignupData()]);
  const [bookIndex, setActiveBookIndex] = useState(0);
  const [orderData, setOrderData] = useState({ bookInfo: [], quantity: 1 });
  const [justWentBack, setJustWentBack] = useState(false);

  // // Hardcoded 3-book order example:
  // const fetchOrderQueryString = 'id=2880854556775&email=phil%40forge.coop'
  // // Hardcoded 1-book order example:
  // const fetchOrderQueryString = 'id=2816266862695&email=phil@forge.coop'
  const fetchOrderQueryString = useMemo(
    () =>
      QueryString.stringify({
        id: queryParams.id || queryParams.order,
        email: queryParams.email,
        store: queryParams.store,
      }),
    [queryParams],
  );
  const signupData = allSignupData[bookIndex] || makeEmptySignupData();
  const orderDataRequest: any = useFetch(`/.netlify/functions/getOrder?${fetchOrderQueryString}`, {
    mode: 'cors',
  });

  let history = useHistory();
  let location = useLocation();

  useEffect(() => {
    const data = orderDataRequest.data || {};
    setOrderData({
      ...data,
      lineItems: data.line_items,
      shippingAddress: data.shipping_address,
      ...(data.bookCustomization || {}),
    });
  }, [orderDataRequest.data]);

  useEffect(() => {
    setSignupData(orderData?.bookInfo || [makeEmptySignupData()]);
  }, [orderData]);

  // Set the state based on the URL.
  useEffect(() => {
    let pageIxFromUrl = getPageIxFromUrl(location.pathname);

    if (pageIxFromUrl !== pageIx) {
      if (!hasMounted || isNaN(pageIxFromUrl)) {
        // Initial load.
        // if (IS_DEV) {
        //   return;
        // }
        history.push('0');
      } else {
        setPageIx(pageIxFromUrl || 0);
      }
    }
  }, [location, hasMounted]);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  const onBack = () => {
    if (pageIx > 0) {
      history.goBack();
    }
    setJustWentBack(true);
    setTimeout(() => setJustWentBack(false), ANIMATION_TIME + 10);
  };

  const onNext = () => {
    const newPageIx = pageIx + 1;
    if (pageIx < 8) {
      history.push('' + newPageIx);
    }
  };

  const goTo = (path: string, backwards?: boolean) => {
    if (!path) {
      return;
    }
    history.push(path);
    if (backwards) {
      setJustWentBack(true);
      setTimeout(() => setJustWentBack(false), ANIMATION_TIME + 10);
    }
  };

  function setNewData(newData: Partial<SignupData>) {
    const nextData = [...allSignupData];
    nextData[bookIndex] = {
      ...signupData,
      ...newData,
    };
    setSignupData(nextData);
  }

  function submitSuccessData(data: any) {
    fetch(`/.netlify/functions/orderSubmitSuccess`, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify({
        id: queryParams.id || queryParams.order,
        email: queryParams.email,
        store: queryParams.store,
        data,
      }),
    }).catch((error) => {});
  }

  function confirmBook(data: SignupData) {
    return fetch('/.netlify/functions/updateOrder', {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify({
        id: queryParams.id || queryParams.order,
        email: queryParams.email,
        store: queryParams.store,
        bookIndex,
        bookData: data,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          throw new Error(data.error);
        }

        setOrderData({
          ...data,
          lineItems: data.line_items,
          shippingAddress: data.shipping_address,
          ...(data.bookCustomization || {}),
        });
        if (data.bookCustomization.complete) {
          history.push('9');

          submitSuccessData(data);
        } else {
          history.push('0');
        }
      });
  }

  const pageProps = {
    signupData,
    setNewData,
    onNext,
    onBack,
    isGoingBack: justWentBack,
    confirmBook,
    orderData,
    orderDataRequest,
    setActiveBookIndex,
    goTo,
  };

  return (
    <>
      <CSSTransitionGroup
        transitionName="example"
        transitionEnterTimeout={ANIMATION_TIME}
        transitionLeaveTimeout={ANIMATION_TIME}
      >
        {pageIx === 0 && <LandingPage {...pageProps} key={0} />}
        {pageIx === 1 && <Who {...pageProps} key={1} />}
        {pageIx === 2 && <Name {...pageProps} key={2} />}
        {pageIx === 3 && <InfoConfirm {...pageProps} key={3} />}
        {pageIx === 4 && <BirthDate {...pageProps} key={4} />}
        {pageIx === 5 && <BirthTime {...pageProps} key={5} />}
        {pageIx === 6 && <BirthLocation {...pageProps} key={6} />}
        {pageIx === 7 && <Dedication {...pageProps} key={7} />}
        {pageIx === 8 && <ConfirmBook {...pageProps} key={8} />}
        {pageIx === 9 && <Success {...pageProps} disableBack={true} key={9} />}
      </CSSTransitionGroup>
    </>
  );
};
export default Flow;

export const ANIMATION_TIME = 500;

// Get everything after the last '/' as a number.
function getPageIxFromUrl(url: string): number {
  var n = url.lastIndexOf('/');
  var result = url.substring(n + 1);
  return parseInt(result, 10);
}

const CutBorder = () => (
  <div
    style={{
      pointerEvents: 'none',
      position: 'fixed',
      bottom: '20px',
      left: '20px',
      right: '20px',
      top: '20px',
    }}
  >
    <div style={{ position: 'absolute', bottom: 0, left: 0, right: 0, top: 0 }}>
      <div
        style={{
          position: 'absolute',
          bottom: '10px',
          left: '0',
          top: '10px',
          borderLeft: `1px solid ${Theme.gray}`,
        }}
      />
      <div
        style={{
          position: 'absolute',
          bottom: '10px',
          right: '0',
          top: '10px',
          borderRight: `1px solid ${Theme.gray}`,
        }}
      />
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: '10px',
          right: '10px',
          borderTop: `1px solid ${Theme.gray}`,
        }}
      />
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          left: '10px',
          right: '10px',
          borderBottom: `1px solid ${Theme.gray}`,
        }}
      />
      <svg
        width="10px"
        height="10px"
        viewBox="0 0 73 73"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ position: 'absolute', top: 0, left: 0 }}
      >
        <path
          d="M0 72L72 0"
          vector-effect="non-scaling-stroke"
          stroke={Theme.gray}
          stroke-width="1"
        />
      </svg>
      <svg
        width="10px"
        height="10px"
        viewBox="0 0 73 73"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ position: 'absolute', bottom: 0, right: 0 }}
      >
        <path
          d="M0 72L72 0"
          vector-effect="non-scaling-stroke"
          stroke={Theme.gray}
          stroke-width="1"
        />
      </svg>
      <svg
        width="10px"
        height="10px"
        viewBox="0 0 73 73"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ position: 'absolute', top: 0, right: 0 }}
      >
        <path
          d="M0 0L72 72"
          vector-effect="non-scaling-stroke"
          stroke={Theme.gray}
          stroke-width="1"
        />
      </svg>
      <svg
        width="10px"
        height="10px"
        viewBox="0 0 73 73"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ position: 'absolute', bottom: 0, left: 0 }}
      >
        <path
          d="M0 0L72 72"
          vector-effect="non-scaling-stroke"
          stroke={Theme.gray}
          stroke-width="1"
        />
      </svg>
    </div>
  </div>
);
