import * as React from 'react';
import { useState, useMemo } from 'react';
import styled from 'styled-components';
import Modal from 'styled-react-modal';
import Button from 'shared/components/Button';
import { BodyText, FlexDiv, HeaderText, Theme } from 'shared/utils/styles';
import { formatName, formatBirthdate, formatBirthtime } from './flow/ConfirmBook';
import { ReactComponent as SVG } from 'shared/assets/svgs/x.svg';
import { SVG_PROPS } from 'shared/components/SetupPage';

const OrderListItem: React.FC<any> = (props) => {
  const { data } = props;
  const { bookInfo } = data;
  return (
    <ListItem>
      <ListItemTitleContainer>
        <ListItemTitle>{data.title}</ListItemTitle>
        <ListItemQuantity>x {data.quantity}</ListItemQuantity>
      </ListItemTitleContainer>
      {props.orderComplete && bookInfo ? (
        <ListItemDescription>
          <Field>
            <FieldLabel>Recipient</FieldLabel>
            <FieldPreview>
              {`${formatName(bookInfo)}, born on ${formatBirthdate(bookInfo)} at ${formatBirthtime(
                bookInfo,
              )} in ${bookInfo.location?.displayAddress}.`}
            </FieldPreview>
          </Field>
          {bookInfo.dedication ? (
            <Field>
              <FieldLabel>DEDICATION</FieldLabel>
              <FieldPreview>{bookInfo.dedication}</FieldPreview>
            </Field>
          ) : null}
        </ListItemDescription>
      ) : null}
    </ListItem>
  );
};

const OrderDetailsModal: React.FC<any> = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [opacity, setOpacity] = useState(0);
  const address = useMemo(() => {
    const shipping = props.orderData?.shippingAddress || {};
    const components = [
      shipping.company,
      shipping.name,
      shipping.address1,
      shipping.address2,
      `${shipping.city}, ${shipping.province_code || shipping.province} ${shipping.zip}`,
      shipping.country,
      shipping.phone,
    ];
    const result: any = [];
    components.forEach((c: string) => {
      if (!!c && c.trim().length) {
        result.push(c);
      }
    });
    return result;
  }, [props.orderData]);
  const orderItems = useMemo(() => {
    const bookInfo = props.orderData?.bookInfo || [];
    const lineItems = props.orderData?.lineItems || [];
    return lineItems.reduce(
      (payload: any, item: any) => {
        if (/BOOK/i.test(item.sku)) {
          let quantity = item.quantity;
          do {
            payload.items.push({
              ...item,
              quantity: 1,
              bookInfo: bookInfo[payload.bookIndex],
            });
            payload.bookIndex++;
            quantity--;
          } while (quantity > 0);
        } else {
          payload.items.push(item);
        }
        return payload;
      },
      { items: [], bookIndex: 0 },
    ).items;
  }, [props.orderData]);
  function toggleModal() {
    setOpacity(0);
    setIsOpen(!isOpen);
  }

  function afterOpen() {
    setTimeout(() => {
      setOpacity(1);
    }, 100);
  }

  function beforeClose() {
    return new Promise((resolve) => {
      setOpacity(0);
      setTimeout(resolve, 300);
    });
  }
  return (
    <>
      {props.simple ? (
        <SimpleButton onClick={toggleModal}>View order details</SimpleButton>
      ) : (
        <Button wide outline onClick={toggleModal} text="View Order Details" />
      )}

      <StyledModal
        isOpen={isOpen}
        afterOpen={afterOpen}
        beforeClose={beforeClose}
        onBackgroundClick={toggleModal}
        onEscapeKeydown={toggleModal}
        opacity={opacity}
        backgroundProps={{ opacity }}
      >
        <ModalCloseButton onClick={toggleModal}>
          <SVG {...SVG_PROPS} />
        </ModalCloseButton>
        <ModalContentContainer>
          {props.orderData?.name && <OrderName>Order {props.orderData.name}</OrderName>}
          <H1>Order Details</H1>
          {props.orderData?.complete ? null : (
            <DescriptionContainer>
              <Description>
                If this information is incorrect, please email{' '}
                <SupportLink href="mailto:support@birthdatecandles.com" target="_blank">
                  support
                </SupportLink>{' '}
                before customizing your book order.
              </Description>
            </DescriptionContainer>
          )}
          <WhiteBoxContainer>
            <WhiteBox>
              <H2>Shipping Address</H2>
              <Address>
                {address.map((c: any, key: any) => (
                  <div key={key}>{c}</div>
                ))}
              </Address>
            </WhiteBox>
          </WhiteBoxContainer>
          <WhiteBoxContainer>
            <WhiteBox>
              <H2>Order Items</H2>
              <OrderItemsList>
                {orderItems.map((data: any, i: any) => (
                  <OrderListItem key={i} data={data} orderComplete={props.orderData?.complete} />
                ))}
              </OrderItemsList>
            </WhiteBox>
          </WhiteBoxContainer>
          <Button wide outline onClick={toggleModal} text="Continue" />
        </ModalContentContainer>
      </StyledModal>
    </>
  );
};

export default OrderDetailsModal;

// "shipping_address": {
//   "address1": "123 Amoebobacterieae St",
//   "address2": "",
//   "city": "Ottawa",
//   "company": null,
//   "country": "Canada",
//   "first_name": "Bob",
//   "last_name": "Bobsen",
//   "latitude": "45.41634",
//   "longitude": "-75.6868",
//   "phone": "555-625-1199",
//   "province": "Ontario",
//   "zip": "K2P0V6",
//   "name": "Bob Bobsen",
//   "country_code": "CA",
//   "province_code": "ON"
// }

const SimpleButton = styled.button`
  cursor: pointer;
  appearance: none;
  background: none;
  text-align: center;
  font-family: 'DomaineText';
  font-size: 16px;
  padding: 2px 0;
  box-shadow: none;
  border: none;
  border-bottom: 1px solid ${Theme.black};
  display: block;
  margin: 2rem auto;
`;

const ModalContentContainer = styled.div`
  box-sizing: border-box;
  overflow: scroll;
  display: block;
  padding: 60px 24px 72px 24px;
  max-height: 100%;
`;
const ModalCloseButton = styled(FlexDiv)`
  position: absolute;
  top: 24px;
  right: 24px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledModal: any = Modal.styled`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  height: auto;
  max-height: 100vh;
  @media (min-height: 840px) {
    max-height: 840px;
  }
  background-color: #FFFCF8;
  opacity: ${(props: any) => props.opacity};
  transition: all 0.3s ease-in-out;
  position: relative;
`;

const H1 = styled(HeaderText)`
  font-size: 38px;
  width: 100%;
  text-align: center;
  margin-bottom: 24px;
`;
const H2 = styled(HeaderText)`
  font-size: 24px;
  line-height: 1;
`;

const OrderItemsList = styled.div`
  flex: 1 1 auto;
  width: 100%;
`;
const ListItemTitle = styled.div`
  font-size: 18px;
  text-align: left;
`;
const ListItemTitleContainer = styled(FlexDiv)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const ListItemQuantity = styled.div`
  font-family: 'TradeGothicNextLTPro';
  font-size: 14px;
  letter-spacing: 0.095em;
  flex-shrink: 0;
  color: #a6a29c;
`;
const ListItem = styled.div`
  padding: 20px 0;
  font-family: 'DomaineText';
  &:not(:first-child) {
    border-top: 1px solid ${Theme.gray};
  }
`;
const ListItemDescription = styled.div`
  text-align: left;
  font-size: 13px;
`;

const ButtonContainer = styled.div`
  margin-bottom: 1rem;
`;

const DescriptionContainer = styled(FlexDiv)`
  width: 100%;
  margin-bottom: 24px;
  justify-content: center;
  flex-direction: column;
`;
const Description = styled(BodyText)`
  text-align: center;
  margin: 0;
`;

const Address = styled(BodyText)`
  text-align: left;
  margin: 16px 0 0 0;
`;
const SupportLink = styled.a`
  color: #0f2756;
`;

const WhiteBoxContainer = styled(FlexDiv)`
  margin-bottom: 24px;
  width: 100%;
  justify-content: center;
`;
const WhiteBox = styled(FlexDiv)`
  background-color: white;
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.11);
  border-radius: 4px;
  padding: 24px;
  box-sizing: border-box;
  flex-direction: column;
  width: 100%;
`;

const Field = styled.div`
  margin-top: 12px;
`;
const FieldLabel = styled.div`
  font-family: 'TradeGothicNextLTPro';
  font-size: 12px;
  text-transform: uppercase;
  margin-bottom: 6px;
  color: #a6a29c;
  letter-spacing: 0.095em;
`;
const FieldPreview = styled.div`
  font-family: 'DomaineText';
  font-weight: 400;
  font-size: 15px;
  overflow-wrap: break-word;
  white-space: pre-line;
`;

const OrderName = styled(FieldLabel)`
  margin-top: -30px;
  margin-bottom: 30px;
`;
